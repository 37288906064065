<template>
  <div>
    <el-form size="small"
             @keyup.enter.native="getDataList(1,1)"
             class="query-form" ref="inputForm" :inline="true" :model="inputForm" label-width="80px">
      <el-form-item label="专家名称" prop="expertName">
        <el-input v-model.trim="inputForm.expertName"
                  placeholder="请输入专家名称(限50字)"
                  maxlength="50" clearable></el-input>
      </el-form-item>
      <el-form-item label="鉴定机构" prop="appraisalInstitution">
        <el-input v-model.trim="inputForm.appraisalInstitution"
                  placeholder="请输入鉴定机构(限50字)"
                  maxlength="50" clearable></el-input>
      </el-form-item>
      <el-form-item label="经手人" prop="agent">
        <el-input v-model.trim="inputForm.agent"
                  placeholder="请输入经手人(限50字)"
                  maxlength="50" clearable></el-input>
      </el-form-item>
      <el-form-item label="鉴定类型" prop="appraisalType">
        <el-select
            v-model="inputForm.appraisalType"
            clearable
            placeholder="请选择鉴定类型"
            style="width: 100%"
        >
          <el-option
              v-for="item in this.$dictUtils.getDictList('appraisal_type')"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="鉴定时间" prop="appraisalDate" class="dateBox">
        <el-date-picker
            v-model="inputForm.appraisalDate"
            type="daterange"
            style="width: 100%;"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">查询</el-button>
        <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置</el-button>
      </el-form-item>
      <el-form-item class="f_r">
        <el-button size="small" @click="bulkExport(0)">
          <i class="icon-piliangdaochu iconfont buIcon"/>
          批量导出
        </el-button>
        <el-button icon="el-icon-download" size="small" @click="bulkExport(1)">
          批量下载
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="dataList"
        size="small"
        v-loading="loading"
        height="calc(100vh - 430px)"
        ref="multipleTable"
        class="table"
        :row-key="'id'"
        @selection-change="selectionChangeHandle"
    >
      <el-table-column :reserve-selection="true" type="selection" width="50"/>
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column prop="appraisalDate" label="鉴定时间" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.appraisalDate | formatDate('YYYY-MM-DD') }}
        </template>
      </el-table-column>
      <el-table-column prop="appraisalInstitution" label="鉴定机构" show-overflow-tooltip></el-table-column>
      <el-table-column prop="expertName" label="专家名称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="agent" label="经手人" show-overflow-tooltip></el-table-column>
      <el-table-column prop="appraisalType" label="鉴定类型" show-overflow-tooltip>
        <template slot-scope="scope" v-if="scope.row.appraisalType.split(',')">
            <span v-for="(item, index) in scope.row.appraisalType.split(',')" :key="index">
              {{ $dictUtils.getDictLabel("appraisal_type", item, '-') }}<span v-if="index!==scope.row.appraisalType.split(',').length-1">、</span>
            </span>
        </template>
      </el-table-column>
      <el-table-column prop="createUserName" label="创建人" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createDate" label="创建时间" width="150px">
        <template slot-scope="scope">
          {{ scope.row.createDate | formatDate }}
        </template>
      </el-table-column>
      <el-table-column prop="updateUserName" label="最后更新人"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="updateDate" label="最后更新时间" width="150px"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.updateDate | formatDate }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="180">
        <template slot-scope="scope">
          <el-button style="margin-bottom: 10px" size="mini" type="text"
                     @click="previewPdf(scope.row.netUrl)">
            预览
          </el-button>
          <el-button style="margin-bottom: 10px" size="mini" type="text"
                     @click="collDetail(scope.row)">
            详情
          </el-button>
          <el-button style="margin-bottom: 10px" size="mini" type="text"
                     @click="downFiile(scope.row.netUrl,scope.row.fileName)">
            下载
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="text_center">
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="current"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="size"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {Base64} from 'js-base64'

export default {
  name: "appraisals",
  props: {
    //藏品id
    id: {
      type: String,
      default: '',
    },
    selectTab:{
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputForm: {
        expertName: '',
        appraisalInstitution: '',
        agent: '',
        appraisalType: '',
        appraisalDate: [],
        appraisalBeginDate: '',
        appraisalEndDate: '',
      },
      dataList: [],
      dataListSelect: [],
      loading: false,
      current: 1,
      size: 10,
      total: 0,
    }
  },

  mounted() {
    this.getDataList()
  },

  methods: {
    getDataList(type, dividePage) {
      if (type == 1) {
        this.current = 1
      }
      if (dividePage) {
        this.$refs.multipleTable.clearSelection()
      }
      if (this.inputForm.appraisalDate && this.inputForm.appraisalDate.length != 0) {
        this.inputForm.appraisalBeginDate = this.inputForm.appraisalDate[0]
        this.inputForm.appraisalEndDate = this.inputForm.appraisalDate[1]
      } else {
        this.inputForm.appraisalBeginDate = ''
        this.inputForm.appraisalEndDate = ''
      }
      this.loading = true
      this.$axios(this.api.collection.collectionappraisalList, {
        ...this.inputForm,
        current: this.current,
        collectionId:this.id,
        size: this.size,
      }, 'get').then((res) => {
        if (res.status) {
          this.dataList = res.data.records
          this.total = parseInt(res.data.total)
          if (this.dataList.length == 0 && this.current != 1) {
            this.current--;
            this.getDataList()
          }
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
      })
    },

    resetting() {
      this.$refs.inputForm.resetFields()
      this.getDataList(1, 1)
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    //批量导出
    bulkExport(type) {
      let ids = []
      let noData = []
      if (this.dataListSelect.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      ids = this.dataListSelect.map(item => {
        if (item.netUrl){
          noData.push(item)
        }
        if (item) {
          return item.appraisalId
        }
      })
      if (!noData.length){
        this.$message.error('暂无附件！')
        return
      }
      let data = {
        ids: ids,
      }
      if (type == 1) {
        this.$message.info('文件已开始下载，请勿重复操作')
        this.$axios(this.api.collection.collectionappraisalresult, {
          collectionId:this.id,
          ids,
        }, 'get','blob').then((res) => {
          let blob = new Blob([res.data], {type: 'application/zip'})
          // let filename = res.headers['content-disposition']
          let filename = '藏品鉴定' + this.inputForm.appraisalDate;
          let link = document.createElement('a')
          link.download = decodeURI(filename)
          link.href = window.URL.createObjectURL(blob)
          link.click()
        })
      }else {
        this.exportExcel(this.api.collection.collectionappraisalExport, data, '藏品鉴定资料', 'get')
        this.getDataList('', 1)
      }
    },

    //预览
    previewPdf(url) {
      if(url){
        window.open(this.$pdfPreviewUrl() + 'onlinePreview?url=' + encodeURIComponent(Base64.encode(url)));
      }else {
        this.$message.error('暂无附件！')
      }
    },

    //详情
    collDetail(row) {
      let listSearchCollDetail = {
        selectTab: this.selectTab,
        inputForm: this.inputForm,
        current: this.current,
        size: this.size,
      }
      sessionStorage.setItem('listSearchCollDetail', JSON.stringify(listSearchCollDetail))
      let routerUrl = {
        path: '/collection/business/collIdentification/addIdentify',
        query: {butType: 5, appraisalId: row.appraisalId,collectionId: this.id},
      }
      this.$emit('gotoOtherDetail',routerUrl)
    },

    downFiile(url, name) {
      if (!url){
        this.$message.error('暂无附件！')
        return
      }
      const xhr = new XMLHttpRequest();
      // 使用open()方法初始化一个请求，第一个参数为请求的类型，第二个参数为请求的地址，第三个参数为是否异步
      xhr.open('GET', url, true);
      // 设置响应的数据类型
      xhr.responseType = 'blob';
      // 当请求加载完成时，触发onload事件
      xhr.onload = () => {
        // 如果请求的状态码为200，表示请求成功
        if (xhr.status === 200) {
          // 创建一个blob对象，第一个参数为响应的数据，第二个参数为blob对象的类型
          const blob = new Blob([xhr.response], {type: xhr.getResponseHeader('content-type')});
          // 创建一个a标签
          const link = document.createElement('a');
          // 为a标签设置href属性，值为blob对象的URL
          link.href = URL.createObjectURL(blob);
          // 为a标签设置下载文件名
          link.download = name;
          // 点击a标签，开始下载文件
          link.click();
        }
      };
      // 发送请求
      xhr.send();
    },


    // 展览每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.getDataList(1);
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
  },
}
</script>

<style scoped>
.switchList {
  font-size: 40px;

  img {
    width: 40px;
    cursor: pointer;
  }
}

.box-card {
  margin-bottom: 10px;

  &::v-deep .el-card__body {
    padding: 10px !important;
  }
}

.noAccessory {
  text-align: center;

  img {
    width: 20%;
  }
}
</style>
